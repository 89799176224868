/**
 * Determines which room to display because UI is limited to three rooms.
 * - If inside, ocean, and balcony are all missing a price, return suite.
 * - Otherwise, return balcony.
 *
 * @param {Object} rooms - An object containing room data.
 * @param {Object} rooms.inside - The inside room data.
 * @param {Object} rooms.ocean - The ocean room data.
 * @param {Object} rooms.balcony - The balcony room data.
 * @param {Object} rooms.suite - The suite room data.
 * @returns {Object|undefined} - The room to display as the third option, or undefined if none exist.
 */
export const getBalconyOrSuiteRoom = ({ inside, ocean, balcony, suite }) => {
  return !inside?.price && !ocean?.price && !balcony?.price ? suite : balcony;
};

/**
 * Finds the first valid room price in priority order (Balcony → Oceanview → Inside → Suite)
 * and calculates the discount. Returns the chosen room’s price, label, and discount.
 *
 * @param {Object} sailing - A sailing object with price and score data for each room type.
 * @param {Number|null} sailing.balcony_price - Balcony price, if available.
 * @param {Number|null} sailing.outside_price - Oceanview price, if available.
 * @param {Number|null} sailing.inside_price - Inside price, if available.
 * @param {Number|null} sailing.suite_price - Suite price, if available.
 * @param {Object} sailing.deal_scores - Scores for each room type.
 * @returns {Object} - An object with { roomPrice, roomLabel, roomDiscount }.
 */
export const getBestRoomInfo = (sailing) => {
  const {
    balcony_price,
    outside_price,
    inside_price,
    suite_price,
    deal_scores,
  } = sailing;

  // Room mappings in order of fallback priority
  const roomTypes = [
    {
      price: balcony_price,
      score: deal_scores?.balcony_score,
      label: "Balcony",
    },
    {
      price: outside_price,
      score: deal_scores?.outside_score,
      label: "Oceanview",
    },
    {
      price: inside_price,
      score: deal_scores?.inside_score,
      label: "Inside",
    },
    {
      price: suite_price,
      score: deal_scores?.suite_score,
      label: "Suite",
    },
  ];

  // Find the first valid room
  const validRoom = roomTypes.find(
    (room) =>
      room.price !== null && room.price !== undefined && room.price !== ""
  ) || { price: null, score: null, label: "N/A" };

  // Calculate discount as a percentage
  const roomDiscount =
    validRoom.score !== null
      ? Math.abs(Math.floor(parseFloat(validRoom.score, 10) * 100))
      : null;

  return {
    roomPrice: validRoom.price,
    roomLabel: validRoom.label,
    roomDiscount,
  };
};
